import { create } from 'zustand';
import { TaskStatuses } from '../utils/enum/taskStatuses';
import { TaskType } from '../utils/enum/taskType';

interface TaskData {
  is_spoof: boolean | null;
}

export interface AntiSpoofInfo {
  audio_id: string;
  task_id: string;
  task_type: TaskType;
  task_status: TaskStatuses | '';
  task_data: TaskData;
}

interface AntiSpoofInfoStore extends AntiSpoofInfo {
  updateAntiSpoofInfo: (newInfo: AntiSpoofInfo) => void;
  updateIsSpoof: (is_spoof: boolean | null) => void;
}

export const useAntiSpoofInfoStore = create<AntiSpoofInfoStore>((set) => ({
  audio_id: '',
  task_id: '',
  task_type: TaskType.VERIFY,
  task_status: '',
  task_data: {
    is_spoof: null,
  },
  updateAntiSpoofInfo: (newInfo: AntiSpoofInfo) => set(newInfo),
  updateIsSpoof: (is_spoof: boolean | null) =>
    set((prev) => ({ ...prev, task_data: { is_spoof } })),
}));
