import cn from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import 'react-circular-progressbar/dist/styles.css';
import { Pixelify } from 'react-pixelify';
import loaderImage from '../../../../assets/loader.png';
import restart from '../../../../assets/restart.svg';
import unanimous from '../../../../assets/unanimous.jpg';
import warning from '../../../../assets/warning.svg';
import { Player, PlayerVerifyResult } from '../../../../components/Player/Player';
import { useAgeInfoStore } from '../../../../stores/useAgeInfo';
import { useCallInfoStore } from '../../../../stores/useCallInfo';
import { useEthnicityInfoStore } from '../../../../stores/useEthnicityInfo';
import { useGenderInfoStore } from '../../../../stores/useGenderInfo';
import { useOperationInfoStore } from '../../../../stores/useOperationInfo';
import { useVoiceToFaceInfoStore } from '../../../../stores/useVoiceToFaceInfo';
import { ActionStatuses } from '../../../../utils/enum/actionStatuses';
import { TaskStatuses } from '../../../../utils/enum/taskStatuses';
import { Ethnicity } from '../../../../utils/helpers/Ethnicity';

import { period } from '../../../../utils/helpers/period';
import styles from './operationResultScreen.module.scss';

type ProgressBarProps = {
  result: ActionStatuses | '/';
  onRestart: () => void;
  pixelSize?: number;
};

const normalizeUrl = async (url: string) => {
  try {
    const response = await fetch(url);

    return await response.blob();
  } catch (error) {
    console.error('error');
  }
};

const handleOperationResult = (
  operationResult: string,
  isError: boolean,
  agePeriodFunc?: typeof period
) => {
  if (operationResult && agePeriodFunc) {
    return agePeriodFunc(+operationResult);
  }

  if (operationResult) {
    return Ethnicity.get(operationResult);
  }

  if (isError) {
    return '-';
  }

  return '?';
};

const OperationResultScreen: FC<ProgressBarProps> = ({ result, onRestart, pixelSize = 10 }) => {
  const ageData = useAgeInfoStore((state) => state.task_data.age);
  const ageTaskStatus = useAgeInfoStore((state) => state.task_status);
  const lists = useCallInfoStore((state) => state.call_data.client.lists);
  const image_url = useVoiceToFaceInfoStore((state) => state.task_data.image_url);
  const imgTaskStatus = useVoiceToFaceInfoStore((state) => state.task_status);
  const ethnicityData = useEthnicityInfoStore((state) => state.task_data.ethnicity);
  const ethnicityTaskStatus = useEthnicityInfoStore((state) => state.task_status);
  const clientName = useCallInfoStore((state) => state.call_data.client.name);
  const genderData = useGenderInfoStore((state) => state.task_data.gender);
  const genderTaskStatus = useGenderInfoStore((state) => state.task_status);
  const { voice2face, age, gender, ethnicity, updateConfiguration } = useOperationInfoStore();

  const [normalImageUrl, setNormalImageUrl] = useState<string | null>(null);
  const [pixelationSize, setPixelSize] = useState(pixelSize);

  useEffect(() => {
    if (image_url) {
      if (!pixelSize || pixelationSize <= 0) return;

      const intervalId = setInterval(() => {
        setPixelSize(pixelationSize - 0.2);
      }, 50);
      return () => clearInterval(intervalId);
    }
  }, [pixelationSize, image_url]);

  useEffect(() => {
    if (image_url) {
      const getImage = async () => {
        const blobUrl = await normalizeUrl(image_url);
        const normalUrl = URL.createObjectURL(blobUrl as Blob);

        setNormalImageUrl(normalUrl);
      };

      getImage();
    }
  }, [image_url]);

  useEffect(() => {
    updateConfiguration();
  }, []);

  return (
    <div className={styles.resultFaceWrapper}>
      <Player verifyResult={result as PlayerVerifyResult}/>
      <div className={styles.speechWrapper}>
        {result === ActionStatuses.FRAUD_DETECTED && (
          <div className={styles.speechContent}>
            <img src={warning} alt="warning"/>
            <div className={styles.speechTitle}>Synthetic speech detected</div>
          </div>
        )}

        {result === ActionStatuses.FRAUD_DETECTED && <div className={styles.wr}></div>}
        <div
          className={cn(styles.imageWrapper, {
            [styles.noFraudNoImage]: result !== ActionStatuses.FRAUD_DETECTED && !voice2face,
            [styles.noFraudWithImage]: result !== ActionStatuses.FRAUD_DETECTED && voice2face,
          })}
        >
          {voice2face && (
            <div className={styles.imageContainer}>
              {imgTaskStatus === TaskStatuses.FINISHED || imgTaskStatus === TaskStatuses.ERROR ? (
                !image_url ? (
                  <img
                    style={{ border: '1px solid #C0C0C0' }}
                    src={unanimous}
                    width={93}
                    height={93}
                    alt="unanimous"
                  />
                ) : (
                  <Pixelify
                    src={normalImageUrl || unanimous}
                    width={93}
                    height={93}
                    centered={true}
                    pixelSize={pixelationSize}
                    fillTransparencyColor="white"
                  />
                )
              ) : (
                <img className={styles.additionalLoader} src={loaderImage} alt=""/>
              )}
            </div>
          )}
          <div>
            <div className={cn(styles.generatedAgeGenderText, styles.spacing)}>
              <div className={styles.contentTextWrapper}>
                <div className={styles.clientName}>{clientName || 'Name unknown'}</div>
              </div>
              {age && (
                <div className={styles.contentTextWrapper}>
                  <div className={styles.titleWrapper}>Age</div>
                  <div className={styles.titleContent}>
                    {handleOperationResult(ageData, ageTaskStatus === TaskStatuses.ERROR, period)}
                  </div>
                </div>
              )}
              {ethnicity && (
                <div className={styles.contentTextWrapper}>
                  <div className={styles.titleWrapper}>Origin</div>
                  <div className={styles.titleContent}>
                    {handleOperationResult(
                      ethnicityData,
                      ethnicityTaskStatus === TaskStatuses.ERROR
                    )}
                  </div>
                </div>
              )}
              {gender && (
                <div className={styles.contentTextWrapper}>
                  <div className={styles.titleWrapper}>Gender</div>
                  <div className={styles.titleContent}>
                    {handleOperationResult(genderData, genderTaskStatus === TaskStatuses.ERROR)}
                  </div>
                </div>
              )}
              {lists && <div className={cn(styles.contentTextWrapper)}>
								<div className={styles.titleWrapper}>Lists</div>
								<div className={styles.listWrapper}>
                  {lists.map((list) => {
                    return (
                      <div
                        className={styles.titleContentList}
                        key={list.org_list_id}>
                        {`${list.org_list_name}`}
                      </div>)
                  })}
								</div>
							</div>}
            </div>
          </div>
        </div>
      </div>

      {(result === ActionStatuses.FRAUD_DETECTED ||
        result === ActionStatuses.TIMEOUT_EXCEEDED ||
        result === ActionStatuses.NOT_VERIFIED) && (
        <div className={styles.actionWrapper}>
          <div className={styles.actionContainer} onClick={onRestart}>
            <img src={restart} alt="restart"/>
            <div className={styles.restartTitle}>Restart verification</div>
          </div>
          <div className={styles.actionContainer}></div>
          <div className={styles.actionContainer}></div>
        </div>
      )}
    </div>
  );
};

export default OperationResultScreen;
