import { io, Socket } from 'socket.io-client';
import { create } from 'zustand';

const WS_URL = process.env.REACT_APP_WS_URL || 'wss://api.corx.corsound.ai';
const token = localStorage.getItem('token') || sessionStorage.getItem('token');

interface Connection {
  socket: Socket;
  setSocket: (socket: Socket) => void;
}

const socket = io(WS_URL, {
  auth: {
    token: token,
  },
});

export const useConnectionStore = create<Connection>((set) => ({
  socket,
  setSocket: (socket: Socket) => set({ socket }),
}));
