export const timeConvert = (num = 0) => {
  let minutes: number | string = Math.floor(Math.round(num) / 60);

  if (minutes.toString().length === 1) {
    minutes = '0' + minutes;
  }
  let seconds: number | string = Math.round(num) % 60;

  if (seconds.toString().length === 1) {
    seconds = '0' + seconds;
  }

  return `${minutes} : ${seconds}`;
};
