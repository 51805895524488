import { create } from 'zustand';
import { TaskStatuses } from '../utils/enum/taskStatuses';
import { TaskType } from '../utils/enum/taskType';
import { MetaData } from './sharedTypes';

interface TaskData {
  enroll_signature: string;
  max_attempts: number;
  target_score: number;
  is_success: boolean | null;
}

export interface VerifyInfo {
  audio_id: string;
  task_id: string;
  task_type: TaskType;
  task_status: TaskStatuses | '';
  task_data: TaskData;
  meta_data: MetaData;
}

interface VerifyInfoStore extends VerifyInfo {
  updateVerifyInfo: (newInfo: VerifyInfo) => void;
  updateVerifyStatus: (task_status: TaskStatuses | '') => void;
  updateIsVerifySuccess: (is_success: boolean | null) => void;
}

export const useVerifyInfoStore = create<VerifyInfoStore>((set) => ({
  audio_id: '',
  task_id: '',
  task_type: TaskType.VERIFY,
  task_status: '',
  task_data: {
    enroll_signature: '',
    max_attempts: 3,
    target_score: 0.5,
    is_success: null,
  },
  meta_data: {
    operator_id: '',
    org_id: 1,
    org_slug: '',
    client_id: '',
  },
  updateVerifyInfo: (newInfo: VerifyInfo) => set(newInfo),
  updateVerifyStatus: (task_status: TaskStatuses | '') => set((prev) => ({ ...prev, task_status })),
  updateIsVerifySuccess: (is_success: boolean | null) =>
    set((prev) => ({ ...prev, task_data: { ...prev.task_data, is_success } })),
}));
