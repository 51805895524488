export enum TaskType {
  VERIFY = 'verify',
  ENROLL = 'enroll',
  VAD = 'vad',
  AGE = 'age',
  GENDER = 'gender',
  ANTI_SPOOF = 'antispoof',
  VOICE_TO_FACE = 'voice2face',
  ETHNICITY = 'ethnicity',
}
