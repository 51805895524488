import { create } from 'zustand';
import { getConfiguration } from '../services/configurationApi';

type OperatorServices =
  | 'age'
  | 'gender'
  | 'antispoof'
  | 'ethnicity'
  | 'voice2face'
  | 'enroll'
  | 'verify';

export type OperatorServicesInfo = Record<OperatorServices, boolean>;

interface OperationInfoStore extends OperatorServicesInfo {
  updateConfiguration: () => void;
  refreshOperationInfo: () => void;
}

const initialStore: OperatorServicesInfo = {
  age: false,
  antispoof: false,
  ethnicity: false,
  gender: false,
  verify: true,
  enroll: true,
  voice2face: false,
};

export const useOperationInfoStore = create<OperationInfoStore>((set) => ({
  ...initialStore,
  updateConfiguration: async () => {
    try {
      const { can } = await getConfiguration();
      set(can);
    } catch (err) {
      console.error(err);
    }
  },
  refreshOperationInfo: () => set(initialStore),
}));
