import { OperatorServicesInfo } from '../stores/useOperationInfo';
import fetch from './api';
import { OPERATOR_CONFIGUARTION_ENDPOINT } from './constants';

type ConfigurationResponse = {
  can: OperatorServicesInfo;
};

export const getConfiguration = async (): Promise<ConfigurationResponse> =>
  fetch.api.get(OPERATOR_CONFIGUARTION_ENDPOINT);
