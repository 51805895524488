import React, { useEffect, FC, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import { useCallInfoStore } from '../../stores/useCallInfo';
import { useConnectionStore } from '../../stores/useConnection';
import { useEnrollInfoStore } from '../../stores/useEnrollInfo';
import { useVadInfoStore } from '../../stores/useVadllInfo';
import { ActionStatuses } from '../../utils/enum/actionStatuses';
import { CallStatus } from '../../utils/enum/callStatuses';
import { TaskStatuses } from '../../utils/enum/taskStatuses';
import { TaskType } from '../../utils/enum/taskType';
import { handleEventMessage } from '../../utils/helpers/handleEventMessage';
import CallerInfo from '../Home/components/CallerInfo/CallerInfo';
import CallIdentify from '../Home/components/CallIdentify/CallIdentify';
import Progressbar from '../Home/components/Progressbar/Progressbar';
import cn from 'classnames';
import styles from './enroll.module.scss';
import Button from '../Home/components/Button/Button';
import { emitCommand, EmitCommandParams } from '../../utils/helpers/emitCommand';

export const Enroll: FC = () => {
  const navigate = useNavigate();

  const [isEnrollAgreed, setIsEnrollAgreed] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const socket = useConnectionStore((state) => state.socket as Socket);

  const callId = useCallInfoStore((state) => state.call_id);
  const clientId = useCallInfoStore((state) => state.call_data.client.client_id);
  const clientName = useCallInfoStore((state) => state.call_data.client.name);
  const callStatus = useCallInfoStore((state) => state.call_status);
  const taskStatus = useEnrollInfoStore((state) => state.task_status);
  const updateCallInfo = useCallInfoStore((state) => state.updateCallInfo);
  const updateEnrollInfo = useEnrollInfoStore((state) => state.updateEnrollInfo);
  const updateVadInfo = useVadInfoStore((state) => state.updateVadInfo);
  const refreshVadTotalSeconds = useVadInfoStore((state) => state.refreshVadTotalSeconds);

  useEffect(() => {
    if (!socket) return;

    socket.on('event', (msg) => {
      handleEventMessage(socket, msg, { updateCallInfo, updateEnrollInfo, updateVadInfo });
    });

    if (callStatus === CallStatus.FINISHED || callStatus === '') {
      navigate('/');
    }

    return () => {
      socket.off('event');
    };
  }, [callStatus, taskStatus]);

  const enrollResult =
    taskStatus === TaskStatuses.ERROR
      ? ActionStatuses.ENROLL_NOT_PASSED
      : ActionStatuses.ENROLL_PASSED;

  const token = localStorage.getItem('token') || sessionStorage.getItem('token');

  const toggleCheckbox = () => setIsChecked(!isChecked);

  const startEnroll = () => {
    const params: EmitCommandParams = {
      socket,
      task_type: TaskType.ENROLL,
      call_id: callId,
      client_id: clientId,
    };

    refreshVadTotalSeconds();
    emitCommand(params);
    setIsEnrollAgreed(!isEnrollAgreed);
  };

  const isAction = localStorage.getItem('action');

  return (
    <>
      {!token && <Navigate to={'/login'} replace={true} />}
      <CallerInfo animated isFinished={taskStatus === TaskStatuses.FINISHED} />
      {isEnrollAgreed || isAction ? (
        taskStatus === TaskStatuses.FINISHED || taskStatus === TaskStatuses.ERROR ? (
          <CallIdentify result={enrollResult} />
        ) : (
          <Progressbar screenType={TaskType.ENROLL} />
        )
      ) : (
        <div>
          <p className={cn(styles.title, styles.modify)}>
            The customer does not have voice signature
          </p>
          <div className={styles.wrapper}>
            <label className={styles.container}>
              <input type="checkbox" checked={isChecked} onChange={toggleCheckbox} />
              <span className={styles.checkmark}></span>
            </label>

            <span>{clientName || 'Unknown'} agrees to create the voice signature</span>
          </div>
          <Button
            titleBtn={'Create voice signature'}
            onClick={startEnroll}
            isDisabled={!isChecked}
          />
        </div>
      )}
    </>
  );
};
