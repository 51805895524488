import React, { useEffect } from 'react';
import { useConnectionStore } from '../../stores/useConnection';
import { emitScreenStatus } from '../helpers/emitCommand';

export const WindowFocusHandler = () => {
  const socket = useConnectionStore((state) => state.socket);

  const onFocus = () => {
    emitScreenStatus(socket, 'focus');
  };

  const onBlur = () => {
    emitScreenStatus(socket, 'unfocus');
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    onFocus();

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return <></>;
};
