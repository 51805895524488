import React, { FC, useEffect } from 'react';
import company from '../../../../assets/company.svg';

import logoGray from '../../../../assets/logo-gray.png';
import { useOperatorProfileStore } from '../../../../stores/useOperatorProfile';
import styles from './mainScreen.module.scss';

export const MainScreen: FC = () => {
  const getOperator = useOperatorProfileStore((state) => state.getProfile);
  const operatorName = useOperatorProfileStore((state) => state.operatorProfile.name);
  const operatorCompany = useOperatorProfileStore((state) => state.operatorProfile.org_name);

  useEffect(() => {
    if (!operatorName) {
      getOperator();
    }
  }, []);

  return (
    <div className={styles.mainWrapper}>
      <div className={styles.mainInfo}>
        <img className={styles.logo} src={logoGray} alt="logo" />
        <h2 className={styles.logoTitle}>Corsound AI</h2>
        <span className={styles.titleUser}>Hello {operatorName}</span>
        <h4 className={styles.description}>Voice Biometric Demo</h4>
        <div className={styles.companyWrapper}>
          <img className={styles.company} src={company} />
          <span className={styles.titleCompany}>{operatorCompany}</span>
        </div>
        <div className={styles.title}>{`Ready for next call`}</div>
      </div>
    </div>
  );
};
