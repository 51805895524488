import { create } from 'zustand';
import { TaskStatuses } from '../utils/enum/taskStatuses';
import { TaskType } from '../utils/enum/taskType';

export interface EthnicityTaskData {
  ethnicity: string;
}

export interface EthnicityInfo {
  audio_id: string;
  task_id: string;
  task_type: TaskType;
  task_status: TaskStatuses | '';
  task_data: EthnicityTaskData;
}

interface EthnicityInfoInfoStore extends EthnicityInfo {
  updateEthnicityInfo: (newInfo: EthnicityInfo) => void;
}

export const useEthnicityInfoStore = create<EthnicityInfoInfoStore>((set) => ({
  audio_id: '',
  task_id: '',
  task_type: TaskType.VERIFY,
  task_status: '',
  task_data: {
    ethnicity: '',
  },
  updateEthnicityInfo: (newInfo: EthnicityInfo) => set(newInfo),
}));
